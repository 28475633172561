import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Form,
  InputGroup,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import Pagination from "../../components/Common/Pagination_ansync";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getTemplates, getTemplatesFilter } from "../../helpers/backend";
import ModalTemplateAdd from "./ModalTemplateAdd";

const Templates = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [expandedTemplate, setExpandedTemplate] = useState(null);
  const [modalAdd, setModalAdd] = useState(false);
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });
 

  const getData = async () => {
    const response = await getTemplates();
    setLoading(true);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    getFilterData(`?search=${search}`);
  };

  const resetSearch = () => {
    getFilterData(`?search=`);
    setSearch("");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getTemplatesFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };

  const toggleExpand = (index) => {
    setExpandedTemplate(expandedTemplate === index ? null : index);
  };

  const renderBodyContent = (body, index) => {
    const maxLength = 100; // Definir el máximo de caracteres para mostrar antes de "ver más"
    if (body.length <= maxLength) {
      return <span>{body}</span>;
    }
    if (expandedTemplate === index) {
      return (
        <>
          <span>{body}</span>
          <Button color="link" onClick={() => toggleExpand(index)}>Ver menos</Button>
        </>
      );
    } else {
      return (
        <>
          <span>{body.substring(0, maxLength)}...</span>
          <Button color="link" onClick={() => toggleExpand(index)}>Ver más</Button>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Templates </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Templates " />

          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="#"
                        onClick={()=> setModalAdd(true)}
                      >
                        Agregar Template{" "}                      
                      </Link>
                      {modalAdd? <ModalTemplateAdd isOpen={modalAdd} onClose={()=> setModalAdd(false)} refresh={()=> getData()} /> :null} 
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Nombre..."
                            value={search}
                            onChange={onChange}
                          />
                          <i className="bx bx-search-alt search-icon" />
                          <Button color="primary" onClick={resetSearch}>
                            <span className="bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                {params.results && params.results.length > 0 ? (
                  <CardBody className="ps-5 pe-5">
                    <h2 className="mb-3 font-size-20">Templates</h2>
                    <div className="table-responsive">
                      <Table
                        className="table  align-middle table-centered  mb-3"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <thead className="table-dark">
                          <tr>
                            <th>#</th>
                            <th>Nombre</th>
                            <th>Campaña</th>
                            <th>Texto</th>
                          </tr>
                        </thead>
                        <tbody>
                          {params.results.map((template, index) => (
                            <tr key={index}>
                              <td><b>{index + 1}</b></td>
                              <td>{template.name} </td>
                              <td>{template.campaign ? template.campaign.map(subItem => subItem[0]).join(', ') : null}</td>
                              <td>{renderBodyContent(template.body, index)}</td>                             
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={30}
                      baseUrl={`whatsapp/template/`}
                      filterFunction={getFilterData}
                    />

                    <Row className="col-lg-12 justify-content-center">
                      <Col md={2}>
                        <Link className="btn btn-secondary text-dark ms-5" to={`/contacts/`}>
                          Volver
                        </Link>
                      </Col>
                    </Row>
                  </CardBody>
                ) : (
                  <CardBody>
                    <h4 className="card-title">No se encontraron resultados</h4>
                  </CardBody>
                )}
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Templates;
